import { PrimaryButton } from "office-ui-fabric-react";
import Papa from "papaparse";
import { models, Report } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { handleAxiosError } from "store/util";
import { getPowerBIEmbedMeta } from "../../store/api/api";
import { AppDispatch } from "../../store/store";
import { PowerBIEmbedMeta } from "../../types/types";

let pagastsList = [];
let fieldsList = [];

export const Analytics2 = () => {
  const [
    embedMetadata = { reportId: null, accessToken: null, embedUrl: "" },
    setEmbedMetadata,
  ] = useState<PowerBIEmbedMeta>();
  const [report, setReport] = useState<Report>();
  const { fleetId } = useParams() as any;
  const dispatch: AppDispatch = useDispatch();
  const [reportType, setReportType] = useState<string>();
  const { t } = useTranslation();

  const [pagasts, setPagasts] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const [selectedPagast, setSelectedPagast] = React.useState(null);
  const [selectedFields, setSelectedFields] = React.useState([]);
  const [reportStatus, setReportStatus] = React.useState("loaded");

  React.useEffect(() => {
    const csv = "/Pagasts.csv";
    Papa.parse(csv, {
      header: true,
      download: true,
      complete: (response) => {
        pagastsList = response.data;
        setPagasts(
          pagastsList.map((el) => {
            return { value: el.LABELID, label: el.LABEL };
          })
        );
      },
    });
  }, []);

  React.useEffect(() => {
    const csv = "/Fields.csv";
    Papa.parse(csv, {
      header: true,
      download: true,
      complete: (response) => {
        fieldsList = response.data;
      },
    });
  }, []);

  const refreshToken = useCallback(() => {
    (async () => {
      let parameters = {
        CountyIds: [],
        FieldIds: selectedFields.map((el) => el.value),
      };
      const refreshEmbedObj: any = await getPowerBIEmbedMeta(
        "insurer",
        parameters
      );
      if (report) {
        report.setAccessToken(refreshEmbedObj.data.accessToken);
      }
      setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
    })();
  }, []);

  useEffect(() => {
    (async () => {
      //let tmid = clientsFilter.join(",");

      let parameters = {
        CountyIds: [],
        FieldIds: selectedFields.map((el) => el.value),
      };
      try {
        const embedObj: any = await getPowerBIEmbedMeta("insurer", parameters);
        setEmbedMetadata(embedObj.data);
      } catch (err) {
        handleAxiosError(err);
        setReportStatus("loaded");
      }
    })();
    setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
  }, [selectedFields]);

  const changePagast = (selection) => {
    setSelectedPagast(pagastsList.find((el) => el.LABELID === selection.value));
  };

  const submitCalculation = () => {
    setReportStatus("loading");
    //alert(selectedFields.map((el) => el.value));
    //setSelectedFields([]);
  };

  useEffect(() => {
    const items = fieldsList
      .filter((el) => el.LABELID === selectedPagast.LABELID)
      .map((el2) => {
        return { value: el2.ID, label: el2.BLOCK_NUMB };
      });
    setFields(items);
  }, [selectedPagast]);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 2 }}>
        <div
          style={{ display: "flex", flexDirection: "column", padding: "20px" }}
        >
          <label
            style={{
              width: "100%",
              flex: 1,
              margin: "10px",
              fontWeight: "bold",
            }}
          >
            Pagasti
          </label>
          <Select options={pagasts} onChange={changePagast} />
          <label
            style={{
              width: "100%",
              flex: 1,
              margin: "10px",
              fontWeight: "bold",
            }}
          >
            ID
          </label>
          <Select
            options={fields}
            isMulti
            value={selectedFields}
            onChange={(el) => setSelectedFields(el as any)}
            isDisabled={selectedPagast === null}
          />
          <PrimaryButton
            style={{ marginTop: "30px" }}
            text="Calculate"
            disabled={!selectedFields || selectedFields.length === 0}
            onClick={submitCalculation}
          />
        </div>
      </div>
      <div style={{ flex: 8 }}>
        {selectedFields && (
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: embedMetadata.reportId,
              embedUrl: embedMetadata.embedUrl,
              accessToken: embedMetadata.accessToken,
              tokenType: models.TokenType.Embed,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
              },
            }}
            cssClassName={"analytics-report-class"}
            getEmbeddedComponent={(embeddedReport) => {
              setReport(embeddedReport as Report);
            }}
          />
        )}
      </div>
    </div>
  );
};
