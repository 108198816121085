import { Analytics2 } from "pages/analytics/Analytics2";
import { FarmerReportPage } from "pages/analytics/FarmerReportPage";
import { InsurerReportPage } from "pages/analytics/InsurerReportPage";
import { Homepage } from "pages/homepage/Homepage";
import { FunctionComponent } from "react";
import { match, matchPath, useLocation } from "react-router-dom";

type RouteDef = {
  key: RouteKey;
  path: string;
  component: FunctionComponent<any>;
  getPath: (...args: any[]) => string;
  matchPath: (loc: any) => match<any> | null;
};

type Routes = {
  home: RouteDef;
  farmer: RouteDef;
  insurer: RouteDef;
  analytics2: RouteDef;
};

export type RouteKey = keyof Routes;

export const routes: Routes = {
  home: {
    key: "home",
    path: "/",
    component: Homepage,
    getPath: () => "/",
    matchPath: (l) =>
      matchPath(l.pathname, {
        path: "/",
        exact: true,
      }),
  },
  farmer: {
    key: "farmer",
    path: "/farmer",
    component: FarmerReportPage,
    getPath: () => "/farmer",
    matchPath: (l) =>
      matchPath(l.pathname, {
        path: "/farmer",
        exact: true,
      }),
  },
  insurer: {
    key: "insurer",
    path: "/insurer",
    component: InsurerReportPage,
    getPath: () => "/insurer",
    matchPath: (l) =>
      matchPath(l.pathname, {
        path: "/insurer",
        exact: true,
      }),
  },
  analytics2: {
    key: "analytics2",
    path: "/analytics2",
    component: Analytics2,
    getPath: () => "/analytics2",
    matchPath: (l) =>
      matchPath(l.pathname, {
        path: "/analytics2",
        exact: true,
      }),
  },
};

export const useCurrentRoute = () => {
  const location = useLocation();
  return Object.values(routes).reduce((match: RouteKey | null, r) => {
    const matchResult = r.matchPath(location);
    if (!!matchResult) {
      return r.key;
    }
    return match;
  }, null);
};
