import { GrecoHeader } from "@greco/components";
import { ISettingsToggle } from "@greco/components/dist/components/GrecoHeader/GrecoPanel/Settings/Settings";
import { errorHandlerClasses, ErrorHandlerMessage } from "library/errorHandler";
import { ActionButton } from "office-ui-fabric-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPhraseToolActivationStatus } from "store/slices/phraseToolSlice";
import { AppContext } from "../../AppContext";
import { inContextEditorPostProcessor } from "../../i18n";
import { MSGraphAuth } from "../../store/api/GraphService";

interface IAppHeaderProps {
  headerConfig?: any;
  user?: any;
  panelStatus?: Function;
  tooltipStatusChange?: any;
  logo?: any;
  logout?: any;
  searchOnChange?: any;
  searchOnSearch?: any;
  search?: string;
  darkMode?: any;
  tooltipsStatus?: any;
}

function AppHeader(props: IAppHeaderProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [shouldShowPhrase, setShouldShowPhrase] = useState(false);
  const appContext = useContext(AppContext);
  const { notifications } = appContext;
  const phraseToggle: ISettingsToggle = {
    title: "Phrase",
    toggleProps: {
      defaultChecked:
        !!new URLSearchParams(history.location.search).get("phrase_context") ||
        inContextEditorPostProcessor.phraseEnabled,
      onChange: (_event, checked) => {
        if (checked) {
          dispatch(setPhraseToolActivationStatus(true));
          inContextEditorPostProcessor.phraseEnabled = true;
        } else {
          dispatch(setPhraseToolActivationStatus(false));
          inContextEditorPostProcessor.phraseEnabled = false;
          const queryParams = new URLSearchParams(history.location.search);
          if (queryParams.get("phrase_context")) {
            queryParams.delete("phrase_context");
            history.push({
              pathname: history.location.pathname,
              search: queryParams.toString(),
            });
          }

          // Reload is needed because we have to remove
          // Phrase scripts from the client
          window.location.reload();
        }
      },
    },
  };

  const enableInContextEditor = async () => {
    const account = await MSGraphAuth.accountObj;
    if (!(account && account.idTokenClaims)) return false;

    const roles: string[] = (account.idTokenClaims as { roles: string[] })
      .roles;
    return !!(roles && roles.includes("PHRASE"));
  };
  useEffect(() => {
    (async () => {
      setShouldShowPhrase(await enableInContextEditor());
    })();
  }, []);

  return (
    <GrecoHeader
      headerConfig={props.headerConfig}
      user={props.user}
      panelStatus={props.panelStatus}
      tooltipStatusChange={props.tooltipStatusChange}
      logo={props.logo}
      logout={props.logout}
      searchOnChange={props.searchOnChange}
      searchOnSearch={props.searchOnSearch}
      search={props.search}
      darkMode={props.darkMode}
      tooltipsStatus={props.tooltipsStatus}
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        helpPanelStrings: {
          panelTitle: t("header.Help"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
        searchPlaceholder: t("header.Search"),
      }}
      additionalSettingsToggles={shouldShowPhrase ? [phraseToggle] : undefined}
      showErrorAndWarningMessages={() => {
        return (
          <>
            {notifications?.map((item) => {
              return (
                <div
                  key={item.notify?.label}
                  onClick={item.handleNotificationClick}
                  className={errorHandlerClasses.notificationMessage}
                >
                  <ErrorHandlerMessage
                    notify={item.notify}
                    errors={null}
                    fieldName={null}
                  />
                </div>
              );
            })}
            <ActionButton
              iconProps={{ iconName: "RingerRemove" }}
              allowDisabledFocus
              styles={{
                root: { float: "right", margin: "10px 10px 0 0" },
              }}
              onClick={() => appContext.clearAllNotifications()}
            >
              {t("greco.clear")}
            </ActionButton>
          </>
        );
      }}
      messagesCount={notifications.length}
    />
  );
}

export default AppHeader;
