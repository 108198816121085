import { GrecoSpinner } from "@greco/components";
import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react";
import { FarmerReportPage } from "pages/analytics/FarmerReportPage";
import { InsurerReportPage } from "pages/analytics/InsurerReportPage";
import React, { Suspense, useState } from "react";
import { useSelector } from "store/hooks";

export const Homepage = () => {
  const userRole = useSelector((s) => (s.auth as any).userRole);
  const [selectedTab, setSelectedTab] = useState(0);
  const _items: ICommandBarItemProps[] = [
    {
      key: "tab0",
      text: "Insurer Report",
      ariaLabel: "Insurer Report",
      iconProps: { iconName: "Shield" },
      className: selectedTab === 0 ? "selectedMenuItem" : "",
      onClick: () => {
        setSelectedTab(0);
      },
    },
    {
      key: "tab1",
      text: "Farmer Report",
      ariaLabel: "Download",
      iconProps: { iconName: "Street" },
      className: selectedTab === 1 ? "selectedMenuItem" : "",
      onClick: () => {
        setSelectedTab(1);
      },
    },
  ];

  return (
    <>
      <Suspense fallback={<GrecoSpinner />}>
        {userRole === "SWI-Insurers" ? <InsurerReportPage /> : null}
        {userRole === "SWI-Admins" ? (
          <>
            <CommandBar items={_items}></CommandBar>
            <div style={{ display: selectedTab === 0 ? "inline" : "none" }}>
              <InsurerReportPage />
            </div>
            <div style={{ display: selectedTab === 1 ? "inline" : "none" }}>
              <FarmerReportPage />
            </div>
          </>
        ) : null}
        {userRole === "SWI-Farmers" ? <FarmerReportPage /> : null}
      </Suspense>
    </>
  );
};
