import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { fork } from "redux-saga/effects";
import { authReducer } from "./auth";
import { settingsReducer, settingsSaga } from "./settings";
import phraseToolSlice from "./slices/phraseToolSlice";

export const history = createBrowserHistory();

const reducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  router: connectRouter(history),
  phraseTool: phraseToolSlice.reducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    routerMiddleware(history),
    sagaMiddleware,
  ],
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = any;

export type LoadStatus = "none" | "loading" | "success" | "error";

sagaMiddleware.run(function* () {
  yield fork(settingsSaga);
});
