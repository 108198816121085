import { AccountInfo } from "@azure/msal-browser";
import { GrecoSpinner } from "@greco/services";
import { initializeIcons } from "@uifabric/icons";
import Axios from "axios";
import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import "react-virtualized/styles.css";
import "../node_modules/@greco/services/dist/lib/main.css";
import App from "./App";
import { SWIBFFParams } from "./config/MSGraphConfig";
import "./i18n";
import "./index.scss";
import "./react-select.css";
import * as serviceWorker from "./serviceWorker";
import { MSGraphAuth } from "./store/api/GraphService";
import { store } from "./store/store";

Axios.interceptors.request.use(async (config) => {
  let authResponse = await MSGraphAuth.getAccessToken(SWIBFFParams);
  if (!authResponse) return config;
  if (config.url?.indexOf("thumbnailPhoto") !== -1) {
    return config;
  }
  config.headers.Authorization = `Bearer ${authResponse.accessToken}`;
  return config;
});

const startApplication = (roles: any) => {
  initializeIcons();
  render(
    <Suspense fallback={<GrecoSpinner />}>
      <Provider store={store}>
        <App roles={roles} />
      </Provider>
    </Suspense>,
    document.getElementById("root")
  );
};

MSGraphAuth.accountObj
  .then((accountInfo: AccountInfo | null) => {
    if (
      accountInfo &&
      accountInfo.idTokenClaims &&
      accountInfo.idTokenClaims.roles
    ) {
      startApplication(accountInfo.idTokenClaims.roles);
    } else {
      MSGraphAuth.login();
    }
  })
  .catch((error) => {
    if (error === "There isn't any account detected.") {
      MSGraphAuth.login();
    } else {
      console.log(error);
    }
  });

serviceWorker.unregister();
