import i18n from "i18next";
import Backend from "i18next-http-backend";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";
import queryString from "query-string";
import { initReactI18next } from "react-i18next";
import { UserDataLoader } from "./store/api/UserDataLoader";

const defaultLanguage = "en-GB";

// creating a language detection plugin
// http://i18next.com/docs/ownplugin/#languagedetector
const languageDetector = {
  type: "languageDetector" as any, // Exact type is 'backend' | 'logger' | 'languageDetector' | 'postProcessor' | 'i18nFormat' | '3rdParty'
  async: true, // flags below detection to be async
  detect: (callback: Function) => {
    return UserDataLoader.getUserData()
      .then((userData) => {
        let lang = userData.preferredLanguage || defaultLanguage;
        callback(lang || "en-GB");
      })
      .catch((error) => {
        callback("en-GB");
      });
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

export const inContextEditorPostProcessor =
  new PhraseInContextEditorPostProcessor({
    phraseEnabled: !!queryString.parse(window.location.search).phrase_context,
    // @ts-ignore
    projectId: process.env.REACT_APP_PHRASE_ID,
    autoLowercase: false,
  });

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .use(inContextEditorPostProcessor)
  .init({
    fallbackLng: defaultLanguage,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_PHRASE_BASE_URL}/{{lng}}.json`,
    },
    postProcess: ["phraseInContextEditor"],
  });

export default i18n;

(window as any).i18n = i18n;
