import { css } from "@emotion/css";
import { Theme } from "@emotion/react";
import { format } from "date-fns";
import moment from "moment";
import { IconButton } from "office-ui-fabric-react";
import * as React from "react";

export const toBase64 = (file: File) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing file"));
    };

    reader.onload = () => {
      resolve(
        reader.result
          ? typeof reader.result === "string"
            ? reader.result.substring(reader.result.indexOf(",") + 1)
            : undefined
          : undefined
      );
    };
    reader.readAsDataURL(file);
  });
};

export function removeEmpty(obj: { [key: string]: any }) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    if (value !== null && value !== undefined && value !== "") {
      result[key] = value;
    }
    return result;
  }, {});
}

export const formatDate = (date: Date) => format(date, "dd.MM.yyyy");

export const formatDoubleFieldValue = (value) =>
  value?.toString()?.replaceAll(".", "")?.replaceAll(",", ".");

export const formatDateWithMoment = (date: Date) =>
  moment(date).format("DD.MM.YYYY");

export const formatDateString = (date: string) => {
  return format(new Date(date), "dd.MM.yyyy");
};

export const formatDateStringWithTime = (date: string) => {
  return format(new Date(date), "dd.MM.yyyy kk:mm:ss");
};

export const formatDateStringTimeOnly = (date: string) => {
  return format(new Date(date), "kk:mm:ss");
};

export const firstLetterUpper = (string: string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const stringToNumber = (str: string) => {
  let _str = str;
  _str = _str.replace(/\./g, "");
  _str = String(_str).replace(",", ".");
  return parseFloat(_str);
};

export const getSelectStyles = (hasError: boolean, theme: Theme): any => {
  return {
    control: (p, s) => {
      return {
        ...p,
        border: 0,
        borderRadius: 0,
        borderBottom: "1px solid",
        borderBottomColor: s.isFocused
          ? hasError
            ? "red"
            : "#005aa1"
          : "#ccc",
        outline: "none",
        "&:hover": {
          borderBottomColor: s.isFocused
            ? hasError
              ? "red"
              : "#005aa1"
            : "#ccc",
        },
        backgroundColor: s.isDisabled ? theme.palette.white : undefined,
      };
    },
  };
};

export const getDatePickerClearSuffix =
  (value: Date | null | any, setValue: (value: Date | null) => void) => () => {
    if (value !== null && value !== undefined && value !== "") {
      return (
        <IconButton
          iconProps={{
            iconName: "ChromeClose",
          }}
          onMouseDown={() => setValue(null)}
          className={css`
            cursor: auto;
            &:hover {
              background: transparent;
            }
            .ms-Icon.ms-Icon {
              font-size: 11px;
              color: #ababab;
            }
          `}
        />
      );
    }
  };

export const capitalize = (s: string) => {
  if (s.length === 0) return "";
  return `${s[0].toUpperCase()}${s.slice(1)}`;
};

export const sanitizeFileName = (s: string) => {
  return s.replace(/[^a-z0-9]/gi, "_").toLowerCase();
};

export const isStringNotEmpty = (v: any) => {
  return typeof v === "string" && v.length > 0;
};

export const isTaxonomyNotEmpty = (v: any) => {
  return v !== null;
};

export const isStringEmpty = (v: any) => {
  return (
    typeof v !== "string" ||
    v === null ||
    v === undefined ||
    (typeof v === "string" && v.length === 0)
  );
};

export const invertColor = (hexTripletColor) => {
  var color = hexTripletColor;
  // color = color.substring(1); // remove #
  // color = parseInt(color, 16); // convert to integer
  // color = 0xffffff ^ color; // invert three bytes
  // color = color.toString(16); // convert to hex
  // color = ("000000" + color).slice(-6); // pad with leading zeros
  // color = "#" + color; // prepend #
  return color;
};

export const hashCode = (s) =>
  s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export const truncateTextIfLong = (
  text: string,
  containerWidth: number,
  averageCharWidthPixels: number = 7
): string => {
  if (text.length * averageCharWidthPixels > containerWidth) {
    let numChars =
      (text.length * averageCharWidthPixels - containerWidth) /
      averageCharWidthPixels;
    if (text.length - Math.round(numChars) < 4) return "…";
    let retVal = text.substr(0, text.length - Math.round(numChars) - 2) + "…";
    return retVal;
  }
  return text;
};
