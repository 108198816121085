import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import { Setting, SettingsResponse } from "../types/types";
import * as API from "./api/api";
import { RootState } from "./store";
import { createAsyncRoutine, handleAxiosError } from "./util";

export type SettingsState = {
  isLoading: boolean;
  isLoadingCreate: boolean;
  isLoadingDelete: boolean;
  isLoadingUpdate: boolean;
  isLoaded: boolean;
};

export const loadSettingsActions = createAsyncRoutine<
  void,
  void,
  SettingsResponse,
  any
>("settings/load");

export const createSettingActions = createAsyncRoutine<
  {
    setting: Partial<Setting>;
    onSuccess: () => void;
  },
  void,
  {
    newSetting: Setting;
    allSettings: Setting[];
  },
  any
>("settings/create");

export const updateSettingActions = createAsyncRoutine<
  {
    setting: Partial<Setting>;
    onSuccess: () => void;
  },
  void,
  Setting[],
  any
>("settings/update");

export const deleteSettingActions = createAsyncRoutine<
  Setting["userAppSettingId"],
  void,
  Setting[],
  any
>("settings/delete");

function* loadSettingsSaga() {
  const tax = yield select(
    (s) => s.taxonomy.ApplicationCode.byCode["Application.SWI"]
  );
  try {
    yield put(loadSettingsActions.loading());
    const res = yield call(API.getAppSettings, tax.id);
    yield put(loadSettingsActions.success(res.data));
  } catch (err) {
    yield put(loadSettingsActions.error(err));
    handleAxiosError(err);
  }
}

export function* loadSettingsSuccessSaga() {
  //yield takeEvery(loadSettingsActions.success.type, function* () {
  // const columnOptionsSettings = selectColumnOptionsSettings(yield select());
  // const defaultView = columnOptionsSettings.find((s) => s.isDefault);
  // if (defaultView) {
  // yield put(setSelectedViewIdFleet(defaultView.userAppSettingId));
  // yield put(setSelectedViewId(defaultView.userAppSettingId));
  // const fleetColumns = selectColumnsSelectedView(yield select());
  // const ravColumns = selectColumnsSelectedViewRav(yield select());
  // yield put(setColumns(fleetColumns));
  // yield put(setRavColumns(ravColumns));
  //}
  // });
}

export function* createSettingSaga() {
  yield takeEvery(
    createSettingActions.trigger.type,
    function* (a: ReturnType<typeof createSettingActions.trigger>) {
      try {
        const { setting: newSettingPartial } = a.payload;
        yield put(createSettingActions.loading());
        const res = yield call(API.addAppSetting, a.payload.setting);
        const allSettings = (res as any).data as Setting[];
        const taxonomy = yield select((s) => s.taxonomy);
        const applicationCodeTax =
          taxonomy.ApplicationCode.byCode["Application.SWI"];

        const newSetting = allSettings.find((s) => {
          return (
            s.applicationCodeId === applicationCodeTax?.id &&
            s.userAppSettingName === newSettingPartial.userAppSettingName
          );
        });
        yield put(
          createSettingActions.success({
            allSettings,
            newSetting,
          })
        );
        a.payload.onSuccess();
      } catch (err) {
        yield put(createSettingActions.error(err));
        handleAxiosError(err);
      }
    }
  );
}

export function* createSettingSuccessSaga() {
  yield takeEvery(
    createSettingActions.success.type,
    function* (a: ReturnType<typeof createSettingActions.success>) {
      const { newSetting } = a.payload;
      // yield put(setSelectedViewIdFleet(newSetting.userAppSettingId));
      // yield put(setSelectedViewId(newSetting.userAppSettingId));
    }
  );
}

export function* updateSettingSaga() {
  yield takeEvery(updateSettingActions.trigger.type, function* (a: any) {
    try {
      yield put(updateSettingActions.loading());
      const res = yield call(API.updateAppSetting, a.payload.setting);
      yield put(updateSettingActions.success(res.data));
      a.payload.onSuccess();
    } catch (err) {
      yield put(updateSettingActions.error(err));
      handleAxiosError(err);
    }
  });
}

export function* deleteSettingSaga() {
  yield takeEvery(deleteSettingActions.trigger.type, function* (a: any) {
    try {
      yield put(deleteSettingActions.loading());
      const res = yield call(API.deleteAppSetting, a.payload);
      // yield put(setSelectedViewIdFleet(null));
      // yield put(setSelectedViewId(null));
      yield put(deleteSettingActions.success(res.data));
    } catch (err) {
      yield put(deleteSettingActions.error(err));
      handleAxiosError(err);
    }
  });
}

export function* settingsSaga() {
  // yield fork(function* () {
  //   yield takeEvery(loadTaxonomyUADActions.success.type, loadSettingsSaga);
  // });
  yield fork(loadSettingsSuccessSaga);
  yield fork(createSettingSaga);
  yield fork(createSettingSuccessSaga);
  yield fork(updateSettingSaga);
  yield fork(deleteSettingSaga);
}

export const settingsAdapter = createEntityAdapter<Setting>({
  selectId: (s) => s.userAppSettingId,
});

export const settingsSlice = createSlice({
  name: "settings",
  initialState: settingsAdapter.getInitialState({
    isLoading: false,
    isLoadingCreate: false,
    isLoadingDelete: false,
    isLoaded: false,
  } as SettingsState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadSettingsActions.loading, (s, a) => {
      s.isLoading = true;
    });
    builder.addCase(loadSettingsActions.success, (s, a) => {
      s.isLoading = false;
      s.isLoaded = true;
      settingsAdapter.setAll(s, a.payload);
    });
    builder.addCase(loadSettingsActions.error, (s, a) => {
      s.isLoading = false;
    });
    builder.addCase(createSettingActions.loading, (s, a) => {
      s.isLoadingCreate = true;
    });
    builder.addCase(createSettingActions.success, (s, a) => {
      const { allSettings } = a.payload;
      settingsAdapter.setAll(s, allSettings);
      s.isLoadingCreate = false;
    });
    builder.addCase(createSettingActions.error, (s, a) => {
      s.isLoadingCreate = false;
    });
    builder.addCase(updateSettingActions.loading, (s, a) => {
      s.isLoadingUpdate = true;
    });
    builder.addCase(updateSettingActions.success, (s, a) => {
      const settings = a.payload;
      settingsAdapter.setAll(s, settings);
      s.isLoadingUpdate = false;
    });
    builder.addCase(updateSettingActions.error, (s, a) => {
      s.isLoadingUpdate = false;
    });
    builder.addCase(deleteSettingActions.loading, (s, a) => {
      s.isLoadingDelete = true;
    });
    builder.addCase(deleteSettingActions.success, (s, a) => {
      const settings = a.payload;
      settingsAdapter.setAll(s, settings);
      s.isLoadingDelete = false;
    });
    builder.addCase(deleteSettingActions.error, (s, a) => {
      s.isLoadingDelete = false;
    });
  },
});

export const settingsReducer = settingsSlice.reducer;

export const {
  selectAll: selectAllSettings,
  selectEntities: selectEntitiesSettings,
  selectById: selectSettingById,
} = settingsAdapter.getSelectors<RootState>((s) => s.settings);

// export const selectColumnOptionsSettings = createSelector<
//   RootState,
//   Setting[],
//   TaxonomyState,
//   Setting[]
// >(
//   selectAllSettings,
//   (s) => s.taxonomy,
//   (settings, taxonomy) => {
//     const ramTaxonomy = taxonomy.ApplicationCode.byCode["Application.SWI"];
//     const columnOptionsSettingTaxonomy =
//       taxonomy.ApplicationSetting.byCode["AppSetting.ColumnOptions"];

//     if (!columnOptionsSettingTaxonomy || !ramTaxonomy) return [];
//     return settings.filter((s) => {
//       return (
//         s.appSettingCodeId === columnOptionsSettingTaxonomy.id &&
//         s.applicationCodeId === ramTaxonomy.id
//       );
//     });
//   }
// );
