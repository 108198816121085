import { Setting } from "@greco/components/dist/types/types";
import axios from "axios";
import { PowerBIEmbedMeta, SettingsResponse } from "../../types/types";

const url = process.env.REACT_APP_BASE_URL;
const uadUrl = process.env.REACT_APP_BFF_HOST_UAD;

export const getPowerBIEmbedMeta = async (
  reportType: string,
  parameters: any
) => {
  var reportTypeParam = reportType ? reportType : "all";
  const res = await axios.post<PowerBIEmbedMeta>(
    `${url}/powerbi/report/${reportTypeParam}`,
    parameters
  );
  return res;
};

export const getAppSettings = async (appID: number) => {
  return await axios.get<SettingsResponse>(`${uadUrl}/appsettings/${appID}`);
};

export const addAppSetting = async (setting: Partial<Setting>) => {
  return await axios.post<Setting[]>(`${uadUrl}/addappsettings`, [setting]);
};

export const deleteAppSetting = async (
  userAppSettingId: Setting["userAppSettingId"]
) => {
  return await axios.delete<Setting[]>(`${uadUrl}/deleteappsettings`, {
    data: [userAppSettingId],
  });
};

export const updateAppSetting = async (setting: Setting) => {
  return await axios.patch<Setting[]>(`${uadUrl}/updateappsettings`, [setting]);
};
